<template>
  <div>
    <div class="search">
      <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-position="left" :style="{height: scrollerHeight}" >
        <div class="box">
          <el-form-item :label="td('订单编号')" prop="orderNo" class="item1">
          <el-input v-model="queryParams.orderNo" :placeholder="td('请输入订单编号')" clearable size="small" @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item :label="td('运单编号')" prop="waybillNo" class="item2">
            <el-input v-model="queryParams.waybillNo" :placeholder="td('请输入运单编号')" clearable size="small"  @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item :label="td('订单来源')" prop="source" class="item3">
            <el-select v-model="queryParams.source" :placeholder="td('订单来源')" clearable>
              <el-option v-for="dict in orderSources" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId" />
            </el-select>
          </el-form-item>
<!--          <el-form-item :label="td('审核订单')" prop="source" class="item3">-->
<!--            <el-select v-model="queryParams.auditOrder" :placeholder="td('审核订单')" clearable>-->
<!--              <el-option v-for="dict in auditOrders" :key="dict.value" :label="dict.name" :value="dict.value" />-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item v-show="showSearchs" style="margin: 24px 0px 0px;" class="ItemShow" >
            <template>
              <el-form-item :label="td('创建日期')">
                <el-date-picker v-model="dateRange" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="~" :start-placeholder="td('开始日期')" :end-placeholder="td('结束日期')" size="small" clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="td('SKU')" prop="skuCode">
                <el-input v-model="queryParams.skuCode" :placeholder="td('请输入SKU')" clearable size="small" @keyup.enter.native="handleQuery" />
              </el-form-item>
              <el-form-item :label="td('店铺')" prop="shopId" class="itemShop">
                <el-select v-model="queryParams.shopId" :placeholder="td('请选择店铺')"  clearable size="small" class="itemShop">
                  <el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name" :value="dict.shopId" size="mini" />
                </el-select>
              </el-form-item>
            </template>
          </el-form-item>
        </div>

        <div class="btns">
          <el-link class="btn11" type="primary" @click="toogleExpands" style="margin-right:16px"><i :class="icon" ></i>{{td(font)}}</el-link>
          <el-button class="btn22" type="primary"  size="small" @click="handleQuery" style="margin-right:16px">{{ td("搜索") }}</el-button >
          <el-button class="btn33" type="primary" plain size="small" @click="resetQuery" >{{ td("重置") }}</el-button >
        </div>
      </el-form>
    </div>

    <div class="threeBtns">
      <el-button class="btn1" icon="el-icon-upload2" type="primary" size="small" @click="handleImport" >{{ td("导入") }}</el-button >
      <el-button class="btn2" icon="el-icon-refresh" type="primary" plain size="small" style="float:right"
      v-loading="fullscreenLoading" element-loading-spinner="el-icon-loading" :element-loading-text="td('加载中')" @click="handlSynchronization">{{ td("同步订单") }}</el-button >
      <el-button class="btn3" icon="el-icon-document" type="primary" size="small" style="float:right;margin-right:16px;" @click="handlBatchSynchronization">{{ td("批量审核") }}</el-button >
    </div>


    <!-- 下表的数据源，分页使用 -->

    <el-table ref="table" :data="orderList" tooltip-effect="dark" row-key="orderId" @selection-change="handleSelectionChange" :row-style="{height: '80px'}"
      :tree-props="{ children: 'children', hasChildren: 'hasChSildren' }" :header-cell-style="{ background: '#F9FCFF', color: '#323233','text-align':'center'}" class="firstTable">
      <el-table-column type="selection" align="center"  />
      <el-table-column align="center" prop="orderNo" :label="td('订单编号')" width="140px">
        <template slot-scope="scope">
          <el-link type="primary" @click="toogleExpand(scope.row)" > {{ scope.row.orderNo }} </el-link>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="waybillNo" :label="td('运单编号')" width="200px" />
      <el-table-column align="center" prop="platformState" :label="td('平台状态')" width="80px" />
<!--      <el-table-column prop="sellerAccount" :label='td("卖家账号")' :show-overflow-tooltip="true" />-->
      <!-- <el-table-column prop="consignee" :label='td("收件人")'  :show-overflow-tooltip="true" /> -->
      <el-table-column align="center" :label='td("订单状态")' width="80px">
        <template slot-scope="scope">{{tfp("ERP_STATE",scope.row.orderState)}}</template>
      </el-table-column>
      <el-table-column align="center" :label="td('订单来源')" width="80px">
        <template slot-scope="scope">{{
          tfp("ERP_ORDER_SOURCE", scope.row.source)
        }}</template>
      </el-table-column>
	  <el-table-column align="center" :label="td('店铺')" width="120px">
	    <template slot-scope="scope">
	  		{{ showShopType(scope.row.shopId)}}
	  		</template>
	  </el-table-column>
      <el-table-column align="center" prop="printTime" :label='td("审核时间")' width="160px" />
      <el-table-column align="center" :label="td('仓库')" width="200">
        <template slot-scope="scope">
          <el-select  v-model="scope.row.depotId" :placeholder="td('请选择仓库')" clearable :disabled="(scope.row.orderState!='10601'||((scope.row.source=='10619' && scope.row.platformState !='ready_to_ship')||(scope.row.source=='10618'&&scope.row.platformState !='PROCESSED')&&(scope.row.source!='10991')))?true:false" size="small" value-key="id">
            <el-option v-for="item in sonList" :key="item.id" :label="td(item.depotName)" :value="item.id" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="logisticName" :label="td('物流公司')" width="100"/>
	  <el-table-column align="center" prop="remark" :label="td('备注')" width="100"/>
      <el-table-column align="center" prop="createTime" :label='td("创建时间")' width="170px"/>
      <!-- <el-table-column :label='td("仓库")' :show-overflow-tooltip="true" >
        <template slot-scope="scope">
          {{td(showDepotList(scope.row.depotId))}}
        </template>
      </el-table-column> -->

      <el-table-column :label='td("操作")' fixed="right" width="80">
        <template slot-scope="scope">
 <el-button size="mini" type="text" icon="el-icon-folder-checked" @click="heyan(scope.row)"
           :disabled="(scope.row.orderState!='10601'||((scope.row.source=='10619' && scope.row.platformState !='ready_to_ship')||(scope.row.source=='10618'&&scope.row.platformState !='PROCESSED')&&(scope.row.source!='10991')))?true:false">{{td("审核")}}</el-button>
           <el-button size="mini" type="text" icon="el-icon-close" @click="quxiao(scope.row)"
           :disabled="(scope.row.orderState!='10601'||((scope.row.source=='10619' && scope.row.platformState !='ready_to_ship')||(scope.row.source=='10618'&&scope.row.platformState !='PROCESSED')&&(scope.row.source!='10991')))?true:false" >{{td("取消")}}</el-button>
         </template>
       </el-table-column>
       <!-- 子列表 -->
       <el-table-column type="expand" width="1">
         <template slot-scope="scope">
           <el-table :data="allotDetailMap.get(scope.row.orderId)" :key="detailTableKey" style=" margin: 0 auto" row-key="detailId" border >
             <el-table-column align="center" :label="td('品名')" prop="reportNameCn" />
             <el-table-column align="center" :label="td('SellerSku')" prop="sellerSku" />
             <el-table-column align="center" :label="td('云仓SKU')" prop="depotSku" />
             <el-table-column align="center" :label="td('ShopSKU')" prop="shopSKu" />
             <el-table-column align="center" :label='td("图片")'   >
               <template slot-scope="scope">
                 <img :src="scope.row.goodsUrl" style="width: 50px; height: 50px" />
               </template>
             </el-table-column>
             <el-table-column align="center" :label='td("数量")'  prop="reportNumber" />
			 <el-table-column align="center" :label="td('规格型号')" prop="model" />
             <!-- <el-table-column :label='td("重量")'  prop="unitWeight" /> -->
             <el-table-column align="center" :label='td("总价")'  prop="totalPrice" />
             <!-- <el-table-column :label='td("币别")'  prop="reportCurrency" /> -->
           </el-table>
         </template>
       </el-table-column>
     </el-table>


   <!-- 导入按钮 -->
   <el-dialog :title="td('订单导入')" :visible.sync="upload.open" width="400px" append-to-body >
     <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url"
       :data="{shopId:upload.shopId,outOrderType:upload.outOrderType}"
       :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag >
       <i class="el-icon-upload"></i>
       <div class="el-upload__text">{{td("将文件拖到此处，或")}}<em>{{td("点击上传")}}</em></div>
       <div class="el-upload__tip text-center" slot="tip">
        <!-- <div class="el-upload__tip" slot="tip">
           <el-checkbox v-model="upload.updateSupport" />
           {{td("是否更新已经存在的用户数据")}}
         </div> -->
         <span>{{td("仅允许导入xls、xlsx格式文件。")}}</span>
         <el-link type="primary" :underline="false" style="font-size: 12px; vertical-align: baseline" @click="importTemplate">{{td("下载模板")}}</el-link
         >
       </div>
     </el-upload>
 	<div style="margin-top: 10px;">
 		<el-select v-model="upload.shopId" :placeholder="td('请选择店铺')"  clearable size="small" class="item3">
 		  <el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name" :value="dict.shopId" size="mini" />
 		</el-select>
 	</div>
   <div style="margin-top: 10px;">
				<el-select v-model="upload.outOrderType" :placeholder="td('请选择出库类型')" clearable size="small">
					<el-option v-for="dict in tf('ERP_OUT_ORDER_TYPE')" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId"
						size="mini" />
				</el-select>
			</div>
     <div slot="footer" class="dialog-footer">
       <el-button @click="upload.open = false" style="margin-right:15px;">{{td("取 消")}}</el-button>
       <el-button type="primary" @click="submitFileForm">{{td("确 定")}}</el-button>

     </div>
   </el-dialog>

   <!-- 批量审核 -->
   <el-dialog :title="td('批量审核')" :visible.sync="handlCheck" @close="handlClose" width="70%" :append-to-body="true" :close-on-click-modal="false" >
     <el-select  v-model="depotNames" @change="getHouse" :placeholder='td("请选择仓库")' clearable >
       <el-option v-for="item in sonList" :key="item.id" :label="item.depotName" :value="item.id" />
     </el-select>

     <!-- <span style="display: block;text-align: center;margin: 20px 0;">您所选中的订单中，根据仓库实际库存数量和截至发货日期累急程度筛选后:</span> -->
       <el-row>
         <el-col :span="11">
           <span style="display: block;text-align: center;margin: 8px 0;color:red">可审核订单: {{ExamineList.length}}</span>
           <el-table :data="ExamineList" style="width: 100%" row-key="orderId" ref="OrderTable" @selection-change="handleSelectionOrderIdChange" :header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
			   <el-table-column type="selection" align="center"  />
			   <el-table-column prop="orderNo" :label="td('订单号')" width="width" />
			   <el-table-column prop="waybillNo" :label="td('面单号')" width="width" />
			   <el-table-column prop="createTime" :label="td('日期')" width="width" />
           </el-table>
         </el-col>
         <el-col :span="11" :offset="2">
           <span style="display: block;text-align: center;margin: 8px 0;color:red">缺货订单: {{subList.length}}</span>
           <el-table :data="subList" style="width: 100%" :header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
             <el-table-column prop="orderNo" :label="td('订单号')" width="width" />
			 <el-table-column prop="waybillNo" :label="td('面单号')" width="width" />
			 <el-table-column prop="createTime" :label="td('日期')" width="width" />
           </el-table>
         </el-col>
       </el-row>
     <!-- 缺方法 -->
     <div slot="footer" class="dialog-footer">
       <el-button type="primary" plain @click="handquxiao"  style="margin-right:15px;">{{td("取 消")}}</el-button>
       <!-- <el-button type="primary"   style="margin-right:15px;">{{td("仅通过现货订单")}}</el-button> -->
       <el-button type="primary" @click="submits" >{{td("确定")}}</el-button>

     </div>
   </el-dialog>

	 <el-dialog :title="td('批量审核情况')" :visible.sync="dialogTableVisible">
	  <div v-for="(test,index) in auditList" :key="index">
		  {{test}}
	  </div>
	 </el-dialog>


   <pagination :total="td(total)" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getOrder" />
 </div>


 </template>

 <script>
 import { getOrderLists , getSonList ,putHeyan ,getCategoryDetail,synchronization,synchronizationLazda,pliaoHetyeyan,checkOrder,BatchAudit } from "@/api/order/orderList";
 import { getListtt,getshop } from '@/api/warehouse'
 import axios from 'axios'

 import { getToken } from "@/utils/auth";
 import Cookies from "js-cookie";

 import {  Debounce,Throttle,addDateRange, tf } from "@/utils/bd.js"

 export default {
   name: "notAudit",
   data() {
     return {
       // 审核订单
       auditOrders:[{name: '可审核订单', value: '1'}, {name: '不可审核订单', value: '0'}],
       // 过滤后的订单来源
       orderSources: [],
       select:'',
       value:'',
       fullscreenLoading: false,
       //选中仓库
       depotIds:null,
       // 条数
       total:0,
       // 非单个禁用
       single: true,
       // 非多个禁用
       multiple: true,
       // 存放选中的批量数据
       plList:[],
       //批量核验 弹窗
       handlCheck: false,
	   dialogTableVisible: false,
       depotNames: null,
       //end
       //存放 订单 管理
       orderList:[],
       //存放 子订单 管理
       sonOrderList:[],
       //子表数据
       allotDetailMap: new Map(),
       //内嵌 table key
         detailTableKey: false,
       //存放 仓库选择
       sonList:[],
	   //店铺列表
	   ShopList: [],
       // 显示搜索条件
       showSearch: true,
       // 总条数
       total: 0,
       // 查询参数
       queryParams: {
         orderNo: null,
         waybillNo: null,
         sellerAccount: null,
         orderState: null,
         source: null,
         skuCode: null,
         auditOrder: null,
         pageNum: 1,
         pageSize: 10,
       },
       dateRange: [],
       // 表单参数
       form: {},
       //搜索 订单状态
       serchState:[],
       //下拉框的值
       selectVal: null,
       upload: {
         // 是否显示弹出层
         open: false,
         // 是否禁用上传
         isUploading: false,
         // 是否更新已经存在的用户数据
         updateSupport: 0,
         // 设置上传的请求头部
         headers: { Authorization: "Bearer " + getToken() },
         // 上传的地址
         url: this.baseURL + "/erp/api/order/importData/",
       },
       showSearchs: false,
       font: '更多',
       icon: 'el-icon-arrow-down',
	   OutstockList: [],
	   ExamineList: [],
	   subList: [],
	   auditList: []
     }
   },
   created() {
     this.getOrder()
     this.getOther()
     this.getShop()
     this.filterOrderSource();
   },
   methods: {
     /** 过滤订单来源数据，只取lazada 10619和shopee 10618的数据 */
     filterOrderSource() {
       let orderSources = tf('ERP_ORDER_SOURCE');
       for (let orderSource of orderSources) {
         if ('10618' === orderSource.detailId || '10619' === orderSource.detailId) {
           this.orderSources.push(orderSource)
         }
       }
     },

     //获取订单表数据
     getOrder() {
       this.queryParams.auditOrder = 0;       // 0 为不可审核
       getOrderLists(addDateRange(this.queryParams,this.dateRange)).then(res => {
         this.orderList = res.rows
         this.total = res.total
       })
     },
     getOther() {
       getListtt().then( res => {
         this.sonList = res.data
		 console.log(res.data)
       })
     },
 	getShop() {       //获取店铺
 	  getshop().then((res) => {
 	    this.ShopList = res.rows;
 	  })
 	},
     getAllotDetail(row) {
       //根据 allotId 获取子表
       getSonList(row).then(res => {
         // 使用 map 结构的方式保存子表
         this.allotDetailMap.set(row,res.rows);
         this.detailTableKey = !this.detailTableKey;
         this.reset();
       })
     },
     //显示子
     toogleExpand(val) {
       this.getAllotDetail(val.orderId);
       let $table = this.$refs.table;
       $table.toggleRowExpansion(val);
     },
     // 表单重置
     reset() {
       this.form = {
         orderNo: null,
         waybillNo: null,
         sellerAccount: null,
         orderState: null,
         source: null,
         skuCode: null,
         orderId: null,
       }
       this.resetForm("form");

     },
     /** 搜索按钮操作 */
     handleQuery() {
       this.queryParams.pageNum = 1;
       this.getOrder();
     },
     /** 重置按钮操作 */
     resetQuery() {
       this.resetForm("queryForm");
       this.queryParams.auditOrder = null;
       this.dateRange = []
       this.handleQuery();
     },

     //核验
     heyan(data) {
       var mydata = [data];
       if (data.depotId == null) {
         return this.$message.warning(this.td("请选择仓库"));
       }
	   let obj = {};
	   obj.depotId = data.depotId;
	   obj.orderIds = new Array;
	   obj.orderIds.push(data.orderId)
       BatchAudit(obj).then((res) => {
	   // checkOrder(obj).then((res) => {
           if (res.code !== 200) {
             return this.$message.error(this.td(res.data[0]));
           }
           this.$message.success(this.td(res.data[0]));
           this.getOrder();
         })
         .catch(() => {
           this.getOrder();
         });

     },
     //取消按钮
     async quxiao(data) {
       const confirmResult = await this.$confirm(
         this.td("此操作将取消订单，是否继续?"),
         this.td("提示"),
         {
           confirmButtonText: this.td("确定") ,
           cancelButtonText: this.td("取消"),
           type: "warning",
         }
       ).catch((err) => err);
       if (confirmResult !== "confirm") return this.$message.info( this.td("已取消"));
       putHeyan(data).then( res => {
         if (res.code !== 200) {
           return this.$message.error(this.td("取消失败"));
         }
         this.$message.success(this.td("取消成功"))
         this.getOrder()
       })
     },

     //同步订单
     handlSynchronization:
       Throttle( function() {
         this.fullscreenLoading = true,
         axios.all([synchronizationLazda(),synchronization()]).then(
           axios.spread( () => {
             this.$message.success(this.td("同步成功"))
             this.fullscreenLoading = false;
             this.getOrder();
           })
         ).catch( () => {
             this.$message.success(this.td("同步成功"))
             this.fullscreenLoading = false;
             this.getOrder();
           })
       },3000),

     //多选
     handleSelectionChange(selection) {
       this.single = selection.length !== 1
       this.multiple = !selection.length
       this.plList = selection
     },
	 handleSelectionOrderIdChange(selection) {
		this.OutstockList = selection;
     },
     getHouse(val) {
       for (let i = 0; i < this.plList.length; i++) {
         this.plList[i].depotId = val;
       }
     },
     handquxiao() {
       this.handlCheck = false
       this.depotNames = null
     },
     handlBatchSynchronization() {
       if (this.plList == '') {
         return this.$message.warning(this.td("请选择批量数据"));
       }
       for (let i =0 ; i < this.plList.length ; i++) {
         if( this.plList[i].orderState !== '10601' ) {
           return this.$message.warning(this.td("选中的数据中，部分已审核，请勿重复操作!"));
         }
       }
	  let orderIdArr = new Array;
	  for (let i =0 ; i < this.plList.length ; i++) {
	    orderIdArr.push(this.plList[i].orderId);
	  }
	   checkOrder(orderIdArr).then(res => {
		   let OrderUtil = this.OrderForm(this.plList,res);
	   })
       this.handlCheck = true
     },
     submits() {
       if (this.depotNames == null) {
         return this.$message.warning(this.td("请选择仓库！"))
       }
	   let obj = {};
	   obj.orderIds = new Array;
	   for (let i = 0 ; i < this.OutstockList.length; i++) {
		   if(obj.orderIds.length == 0){
			obj.depotId = this.depotNames;
			obj.orderIds.push(this.OutstockList[i].orderId);
		   }else {
			   for(let x = 0 ; x < obj.orderIds.length; x++) {
				   if(obj.orderIds[x] != this.OutstockList[i].orderId){
					   obj.orderIds.push(this.OutstockList[i].orderId);
					   if(obj.orderIds.length == this.OutstockList.length){
						   break;
					   }
				   }
			   }
		   }
	   }
       BatchAudit(obj).then( res => {
		this.auditList = res.data;
		this.dialogTableVisible = true;
         this.depotNames = null
         this.handlCheck = false
		 this.getOrder();
       }).catch( () => {
         this.depotNames = null
         this.handlCheck = false
       })
     },
	 showToast(test){
		this.$message.info(test);
	 },
     handlClose() {
       this.getOrder();
       this.depotNames = null;
	   this.subList = [];
	   this.OutstockList = [];
	   this.ExamineList = [];

     },



     handleFileUploadProgress(event, file, fileList) {
       this.upload.isUploading = true;
     },
     handleFileSuccess(response, file, fileList) {
       this.upload.open = false;
       this.upload.isUploading = false;
       this.$refs.upload.clearFiles();
       this.$alert(response.msg, this.td("导入结果"), { dangerouslyUseHTMLString: true });
       this.getOrder()
     },
     handleImport() {
       this.upload.open = true;
     },
     /** 下载模板操作 */
     importTemplate() {
       // this.download(
       //   "https://interface.6pl.com.cn/erp/order/importTemplate",
       //   {
       //     ...this.queryParams,
       //   },
       //   `order_${new Date().getTime()}.xlsx`
       // );
	   	window.open('/excel_model/ERP导入表格.xlsx',"_blank");
     },
     // 文件上传中处理
     handleFileUploadProgress(event, file, fileList) {
       this.upload.isUploading = true;
     },
     // 文件上传成功处理
     handleFileSuccess(response, file, fileList) {
       this.upload.open = false;
       this.upload.isUploading = false;
       this.$refs.upload.clearFiles();
       this.$alert(response.msg, this.td("导入结果"), { dangerouslyUseHTMLString: true });
       this.getOrder();
 	  this.upload.shopId = null;
     },
     // 提交上传文件
     submitFileForm() {
       if(this.upload.shopId == null){
       		this.$message.info( this.td("请选择店铺"))
       }else {
       		// this.upload.url = 'https://interface.6pl.com.cn' + "/erp/order/importData?shopId="+this.upload.shopId,
 			// this.upload.url = 'https://interface.6pl.com.cn' + "/erp/order/importData/"+this.upload.shopId,
       		console.log(this.upload);
       		this.$refs.upload.submit();
       }
     },
     toogleExpands() {
       if (this.showSearchs) {
         this.showSearchs = !this.showSearchs
         this.icon = 'el-icon-arrow-down'
         // this.font = '更多'
		 this.font = this.td("更多")
       }else {
         this.showSearchs = !this.showSearchs
         // this.font = '收起'
		 this.font = this.td("收起")
         this.icon = 'el-icon-arrow-up'
       }
     },
	 OrderForm(data,val){
		let order = -1;
		for (let i = 0;i < data.length;i++) { //选中数组
		  let Oindex = this.findOrder(data[i].orderId,val);
		  console.log(data[i]);
		  if(Oindex == -1){
			  this.ExamineList.push(data[i]);
		  }else {
			  this.subList.push(data[i]);
		  }
		}
	 },
	 findOrder(orderId,val){
	 	let index = -1;
	 	   for (let x = 0;x < val.length;x++) { //查询数组
	 		  if(orderId == val[x].orderId){
				  index = x;
	 			  break;
	 		  }else {
	 			 continue;
	 		  }
	 	   }
		return index;
	}
   },
   computed: {
 	  //显示仓库名字
 	  showDepotList() {
 	    return function (number) {
 	      for (let i = 0; i < this.sonList.length; i++) {
 	        if (number == this.sonList[i].id) {
 	          return this.sonList[i].depotName;
 	        }
 	      }
 	    }
 	  },
      scrollerHeight: function() {
       return this.showSearchs ? 160 + 'px':80 + 'px'
     },
 	showShopType: function() {
 	  return function(number) {
 	    for (let i = 0; i < this.ShopList.length; i++) {
 	      if (number == this.ShopList[i].shopId) {
 	        return this.ShopList[i].name
 	      }
 	    }
 	  }
 	},
   }
 }
 </script>

 <style scoped>
 .search {
   position: relative;
   background-color: #fff;
 }
 .search .box {
   position: absolute;
   top: 20px;
 }
 .search .el-form {
   padding: 0 16px;
   color: #333333;
   margin-right: 0;
   border-bottom: 16px solid #F1F3FF;
 }
 .search .el-form-item {
   margin-bottom: 0;
   margin-right: 0;
 }
 .search ::v-deep .el-form-item__label {
   margin-right: 24px;
   padding: 0;
 }
 .search ::v-deep .el-input__inner {
   width: 220px;
 }
 .search ::v-deep .el-input__inner {
   height: 32px;
 }

 .el-form-item:not(:first-child) {
   margin-left: 32px;
 }
 .btns {
   position: absolute;
   top: 24px;
   right: 16px;
 }
 .btns .el-button {
   width: 74px;
   height: 32px;
 }
 .btns .btn11,
 .btn22 {
   margin-right: 16px;
 }

 .input-with-select ::v-deep .el-input-group__prepend {
   background-color: #fff;
 }
 /* .search .item3,.item4 ::v-deep .el-input__icon {
   line-height: 40px;
 }

 .search .el-date-picker ::v-deep .el-input__icon {
   line-height: 31px!important;
 } */

 ::v-deep .el-button + .el-button {
   margin: 0;
 }

 ::v-deep .el-loading-spinner {
   margin-top: -7px;
   margin-left: -30px;
 }
 ::v-deep .el-loading-spinner .el-loading-text {
   margin: -12px 0 0 60px;
 }



 .threeBtns {
   background-color: #fff;
   padding: 24px 16px;
 }
 .btn1 {
   width: 80px;
   height: 32px;
 }
 .btn2 {
   width: 108px;
   height: 32px;
 }
 .btn3 {
   width: 108px;
   height: 32px;
 }
 .firstTable {
   padding: 0 16px;
 }


  /* 110% */
 @media screen and (max-width: 1800px) {
     .search ::v-deep .el-input__inner {
   width: 200px;
 }

   .search ::v-deep .el-form-item__label {
     margin-right: 12px;
   }

   .el-form-item:not(:first-child) {
     margin-left: 16px;
   }
   .btns {
     position: absolute;
     top: 24px;
     right: 0px;
   }

   .btns .btn11 {
     margin-right: 16px;
     /* transform: translate(23px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(23px, 0) scale(0.65, 0.65);
     -moz-transform: translate(23px, 0) scale(0.65, 0.65);
     -o-transform: translate(23px, 0) scale(0.65, 0.65); */
   }
   .btns .btn22 {
     margin-right: 16px;
     /* transform: translate(18px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(18px, 0) scale(0.65, 0.65);
     -moz-transform: translate(18px, 0) scale(0.65, 0.65);
     -o-transform: translate(18px, 0) scale(0.65, 0.65); */
   }
   .btns .btn33 {
     margin-right: 16px;
     /* transform: scale(0.65, 0.65);
     -webkit-transform: scale(0.65, 0.65);
     -moz-transform: scale(0.65, 0.65);
     -o-transform: scale(0.65, 0.65); */
   }
   /* .btn1 {
     transform: translate(-15px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
     -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
     -o-transform: translate(-15px, 0) scale(0.65, 0.65);
   }
   .btn2 {
     transform: translate(20px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(20px, 0) scale(0.65, 0.65);
     -moz-transform: translate(20px, 0) scale(0.65, 0.65);
     -o-transform: translate(20px, 0) scale(0.65, 0.65);
   }
   .btn3 {
     transform: translate(-15px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(60px, 0) scale(0.65, 0.65);
     -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
     -o-transform: translate(-15px, 0) scale(0.65, 0.65);
   } */
 }
 /* 125% */
 @media screen and (max-width: 1620px) {
     .search ::v-deep .el-input__inner {
   width: 160px;
 }

   .search ::v-deep .el-form-item__label {
     margin-right: 12px;
   }

   .el-form-item:not(:first-child) {
     margin-left: 16px;
   }
   .btns {
     position: absolute;
     top: 24px;
     right: 0px;
   }

   .btns .btn11 {
     margin-right: 0px;
     transform: translate(23px, 0) scale(0.85, 0.85);
     -webkit-transform: translate(10px, 0) scale(0.85, 0.85);
     -moz-transform: translate(23px, 0) scale(0.85, 0.85);
     -o-transform: translate(23px, 0) scale(0.85, 0.85);
   }
   .btns .btn22 {
     margin-right: 16px;
     transform: translate(16px, 0) scale(0.85, 0.85);
     -webkit-transform: translate(16px, 0) scale(0.85, 0.85);
     -moz-transform: translate(16px, 0) scale(0.85, 0.85);
     -o-transform: translate(16px, 0) scale(0.85, 0.85);
   }
   .btns .btn33 {
     transform: scale(0.85, 0.85);
     -webkit-transform:translate(0px, 0) scale(0.85, 0.85);
     -moz-transform: scale(0.85, 0.85);
     -o-transform: scale(0.85, 0.85);
   }
   .btn1 {
     transform: translate(-5px, 0) scale(0.85, 0.85);
     -webkit-transform: translate(-5px, 0) scale(0.85, 0.85);
     -moz-transform: translate(-5px, 0) scale(0.85, 0.85);
     -o-transform: translate(-5px, 0) scale(0.85, 0.85);
   }
   .btn2 {
     transform: translate(20px, 0) scale(0.85, 0.85);
     -webkit-transform: translate(7px, 0) scale(0.85, 0.85);
     -moz-transform: translate(20px, 0) scale(0.85, 0.85);
     -o-transform: translate(20px, 0) scale(0.85, 0.85);
   }
   .btn3 {
     transform: translate(28px, 0) scale(0.85, 0.85);
     -webkit-transform: translate(28px, 0) scale(0.85, 0.85);
     -moz-transform: translate(28px, 0) scale(0.85, 0.85);
     -o-transform: translate(28px, 0) scale(0.85, 0.85);
   }
 }
 /* 150% */
 @media screen and (max-width: 1500px) {
     .search ::v-deep .el-input__inner {
   width: 160px;
 }

   .search ::v-deep .el-form-item__label {
     margin-right: 16px;
   }

   .el-form-item:not(:first-child) {
     margin-left: 0px;
   }

   .item1 {
     transform: translate(-30px,0) scale(0.85, 0.85);
     -webkit-transform: translate(-30px,0) scale(0.75, 0.75);
     -moz-transform: translate(-30px,0) scale(0.85, 0.85);
     -o-transform: translate(-30px,0) scale(0.85, 0.85);
   }
   .item2 {
     transform: translate(-67px,0) scale(0.85, 0.85);
     -webkit-transform: translate(-67px,0) scale(0.75, 0.75);
     -moz-transform: translate(-67px,0) scale(0.85, 0.85);
     -o-transform: translate(-67px,0) scale(0.85, 0.85);
   }
   .item3 {
     transform: translate(-104px,0) scale(0.85, 0.85);
     -webkit-transform: translate(-104px,0) scale(0.75, 0.75);
     -moz-transform: translate(-104px,0) scale(0.85, 0.85);
     -o-transform: translate(-104px,0) scale(0.85, 0.85);
   }
   .item4 {
     transform: translate(-143px,0) scale(0.85, 0.85);
     -webkit-transform: translate(-143px,0) scale(0.75, 0.75);
     -moz-transform: translate(-143px,0) scale(0.85, 0.85);
     -o-transform: translate(-143px,0) scale(0.85, 0.85);
   }

   .btns {
     position: absolute;
     top: 24px;
     right: 0px;
   }

   .btns .btn11 {
     margin-right: 0px;
     transform: translate(35px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(35px, 0) scale(0.65, 0.65);
     -moz-transform: translate(35px, 0) scale(0.65, 0.65);
     -o-transform: translate(35px, 0) scale(0.65, 0.65);
   }
   .btns .btn22 {
     margin-right: 0px;
     transform: translate(28px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(28px, 0) scale(0.65, 0.65);
     -moz-transform: translate(28px, 0) scale(0.65, 0.65);
     -o-transform: translate(28px, 0) scale(0.65, 0.65);
   }
   .btns .btn33 {
     transform:  translate(13px, 0) scale(0.65, 0.65);
     -webkit-transform:translate(13px, 0) scale(0.65, 0.65);
     -moz-transform:translate(13px, 0) scale(0.65, 0.65);
     -o-transform:translate(13px, 0) scale(0.65, 0.65);
   }

   .btn1 {
     transform: translate(-15px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
     -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
     -o-transform: translate(-15px, 0) scale(0.65, 0.65);
   }
   .btn2 {
     transform: translate(18px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(18px, 0) scale(0.65, 0.65);
     -moz-transform: translate(18px, 0) scale(0.65, 0.65);
     -o-transform: translate(18px, 0) scale(0.65, 0.65);
   }
   .btn3 {
     transform: translate(60px, 0) scale(0.65, 0.65);
     -webkit-transform: translate(60px, 0) scale(0.65, 0.65);
     -moz-transform: translate(60px, 0) scale(0.65, 0.65);
     -o-transform: translate(60px, 0) scale(0.65, 0.65);

   }
 }
@media screen and (min-width: 2510px){
	.item4 {
		margin-right: 32px !important;
	}
}
@media screen and (min-width: 2561px){
	.ItemShow {
		margin: 0 0 0 0px !important;
	}
}
 </style>
