import request from '@/utils/request'
import { getToken } from "@/utils/auth"

export function getListtt(query) {
  return request({
	url:'/erp/erpDepot/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params : query
  })
}

export function getList() {
  return request({
    url:'/wms/depot/erpList?',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
  })
}
//新增仓库
export function addWarehouse(data) {
  return request({
    url:'/erp/erpDepot/erp',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data : data
  })
}

//获取国家ID
export function getCity() {
  return request({
    url:'/system/region/erpTreeList',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

//获取店铺
export function getshop() {
  return request({
    url:'/erp/shop/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}

//获取入库批次
export function getinordersa(query) {
  return request({
    url:'/erp/inorder/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
	  params : query
  })
}
//获取入库批次（子）
export function getinorders(query) {
  return request({
    url:'/erp/indetail/erplist?id=' + query,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',

  })
}



//获取商品SKU
export function getson(val) {
  return request({
    url:'/erp/SkuSon/erpMotherlist?skuMother=' + val,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',

  })
}

//获取商品SKU
export function getsons(query) {
  return request({
    url:'/erp/SkuSon/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

export function getrelation(query) {
  return request({
    url:'/wms/goods/listForErp',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
	params : query
  })
}

//添加入库批次
export function addinorder(data) {
  return request({
    url:'/erp/inorder/erpaddInOrder',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post' ,
	data : data
  })
}

//修改入库批次
export function updateInOrder(data) {
  return request({
    url:'/erp/inorder/erpupdateInOrder',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'put' ,
	data : data
  })
}

//删除入库批次
export function delInorder(data) {
  return request({
    url:'/erp/inorder/erp/' + data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'delete'
  })
}

/**
 * 根据采购入库明细id，查询店铺库存明细
 */
export function getDetailShop(detailId) {
    return request({
      url: '/erp/inorderDetailShop/getDetailShop/' + detailId,
      headers: {
        "Authorization": "Bearer  " + getToken()
      },
      method: 'get'
    })
}

