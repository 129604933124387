import request from '@/utils/request'
import { getToken } from "@/utils/auth"

export function getOrderList(query) {
  return request({
    url: '/erp/api/order/list',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params : query
  })
}

export function getOrderLists(query) {
  return request({
    url: '/erp/api/order/waitAuditList',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params : query
  })
}

/**
 *  审核异常单查询
 * @param query
 */
export function getAuditException(query) {
  return request({
    url: '/erp/api/order/auditException',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params : query
  })
}

//子订单列表
export function getSonList(id) {
  return request({
    // url: '/erp/api/orderDetail?orderId=' + id,
	url: '/erp/api/order/orderDetail/' + id,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
  })
}

//搜索状态
export function getCategoryDetail(language,languageId) {
	return request({
		url: '/tms/term/categoryDetail/'+language+"/"+languageId,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
		method: 'get',
	})
}

//核验、取消
export function cancelOrder(data) {
	return request({
      url: '/erp/order/cancelOrder',
      headers: {
        "Authorization": "Bearer  " + getToken()
      },
		method: 'post',
      data: data
	})
}

//核验、取消
export function putHeyan(data) {
  return request({
    url: '/erp/order',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'put',
    data: data
  })
}

//批量旧
export function pliaoHetyeyan(data) {
  return request({
    url:'/erp/order/batchReview',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'post',
    data : data
  })
}

//批量 新
export function BatchAudit(data) {
  return request({
    url:'/erp/api/order/audit',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'post',
    data : data
  })
}

//同步订单(shopee)
export function synchronization() {
  return request({
    url:'/shopee/test/get_order_list2',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
  })
}
//同步订单(lazada)
export function synchronizationLazda() {
  return request({
    url:'lazada/Order/getOrders',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'post',
  })
}

//同步SKU (lazada)
export function synchronizationSku() {
  return request({
    url:'lazada/Product/getProducts',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
  })
}

//同步SKU (shopee)
export function synchronizationSkuShopee() {
  return request({
    url:'shopee/test/get_item_list2',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'get',
  })
}

//批量同步SKU
export function synchronizationPlTbu(data) {
  return request({
    url:'/erp/Erpmother/batchSync/'+ data,
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'put',
  })
}

//检测库存
export function checkOrder(data){
  return request({
    url:'/erp/api/order/checkOrderStock',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'post',
	data: data
  })
}

//手工新增保存接口
export function manualImportData(data){
  return request({
    url:'/erp/api/order/manualImportData',
    headers: {
      "Authorization": "Bearer  " + getToken(),
    },
    method: 'post',
	  data: data
  })
}
